<template>
  <v-row justify="center">
    <div
      style="
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
      "
    >
      <canvas
        id="confettiCanvas"
        class="sticky"
        style="z-index: 5; pointer-events: none"
      >
      </canvas>
    </div>
    <v-col cols="10" md="6" lg="4">
      <v-row align="center" justify="center">
        <v-col cols="12" class="text-center primary--text text-h3">
          <v-row justify="center"
            ><v-img
              src="@/assets/images/VitrueVidaLogoWithText.png"
              max-width="350"
              contain
            ></v-img
          ></v-row> </v-col
      ></v-row>
      <v-row v-if="feedbackSent" align="center" justify="center"
        ><v-col
          id="completeMessage"
          cols="12"
          class="text-center primary--text text-h4"
          >{{ $t("feedback.page.complete") }}</v-col
        ></v-row
      >
      <v-row v-else align="center" justify="center">
        <v-col
          cols="12"
          class="text-start text-h6"
          v-if="showRating"
          id="ratingField"
          ><v-row justify="center" no-gutters>
            <FeedbackEmojis v-model="reaction" /> </v-row
        ></v-col>
        <v-col cols="12" v-if="showEmail" id="emailField">
          <v-text-field
            prepend-inner-icon="email"
            :label="$t('textFields.email')"
            placeholder="janedoe@email.com"
            v-model="emailAddress"
            outlined
            height="68"
            data-cy="email"
            hide-details
          ></v-text-field
        ></v-col>
        <v-col cols="12" class="text-start text-h6" id="textField">
          {{ $t("feedback.page.moreInfoTitle") }}
          <v-textarea
            ref="textArea"
            outlined
            v-model="textFeedback"
            auto-grow
            no-resize
            class="text-area-height-limit"
        /></v-col>
        <v-col cols="12"
          ><v-row justify="center"
            ><v-btn
              id="submitButton"
              rounded
              color="primary"
              min-width="200px"
              @click="submitFeedback"
              :disabled="sending"
              :loading="sending"
              >{{ $t("buttons.submit") }}</v-btn
            ></v-row
          ></v-col
        >
      </v-row></v-col
    >
  </v-row>
</template>

<script>
import FeedbackEmojis from "@/components/common/Feedback/FeedbackEmojis.vue";
import EmojiReactions from "@/assets/json/common/EmojiReactions.json";
import { createFeedback, updateFeedback } from "@/customApi";

import Vue from "vue";
import VueConfetti from "vue-confetti";
Vue.use(VueConfetti);
export default {
  components: {
    FeedbackEmojis
  },
  data() {
    return {
      feedbackSent: false,
      reaction: undefined,
      emailAddress: "",
      showEmail: false,
      showRating: false,
      textFeedback: "",
      feedbackId: undefined,
      sending: false
    };
  },
  async mounted() {
    let paramString = window.location.href.split("?")[1];
    let queryString = new URLSearchParams(paramString);
    let email = queryString.get("email");
    let rating = queryString.get("rating");
    if (email) {
      this.emailAddress = email;
    } else {
      this.showEmail = true;
    }
    if (rating != null && rating != undefined) {
      this.reaction = EmojiReactions.find(r => r.value == rating);
    } else {
      this.showRating = true;
    }

    if (this.emailAddress) {
      this.feedbackId = await createFeedback(this.feedbackDto);
    }
  },
  computed: {
    feedbackDto() {
      return {
        Id: this.feedbackId,
        Email: { value: this.emailAddress },
        Score: this.reaction.value,
        WrittenFeedback: this.textFeedback,
        Url: this.$route.path
      };
    }
  },
  methods: {
    async submitFeedback() {
      try {
        this.sending = true;
        if (this.feedbackId) {
          await updateFeedback(this.feedbackDto);
        } else {
          await createFeedback(this.feedbackDto);
        }
      } catch (err) {
      } finally {
        this.sending = false;
        this.feedbackSent = true;
        this.$nextTick(() => {
          this.setupConfetti();
        });
      }
    },
    setupConfetti() {
      this.$confetti.start({
        canvasElement: this.$refs.confettiCanvas,
        particlesPerFrame: 1.25,
        particles: [
          {
            type: "rect"
          }
        ]
      });

      setTimeout(() => {
        this.$confetti.stop();
      }, 3000);
    }
  }
};
</script>

<style scoped>
.text-area-height-limit {
  max-height: 350px;
  overflow-y: auto;
}
</style>
