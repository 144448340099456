var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "div",
        {
          staticStyle: {
            position: "absolute",
            top: "0",
            height: "100%",
            width: "100%",
            "pointer-events": "none"
          }
        },
        [
          _c("canvas", {
            staticClass: "sticky",
            staticStyle: { "z-index": "5", "pointer-events": "none" },
            attrs: { id: "confettiCanvas" }
          })
        ]
      ),
      _c(
        "v-col",
        { attrs: { cols: "10", md: "6", lg: "4" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-center primary--text text-h3",
                  attrs: { cols: "12" }
                },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c("v-img", {
                        attrs: {
                          src: require("@/assets/images/VitrueVidaLogoWithText.png"),
                          "max-width": "350",
                          contain: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.feedbackSent
            ? _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center primary--text text-h4",
                      attrs: { id: "completeMessage", cols: "12" }
                    },
                    [_vm._v(_vm._s(_vm.$t("feedback.page.complete")))]
                  )
                ],
                1
              )
            : _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _vm.showRating
                    ? _c(
                        "v-col",
                        {
                          staticClass: "text-start text-h6",
                          attrs: { cols: "12", id: "ratingField" }
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center", "no-gutters": "" } },
                            [
                              _c("FeedbackEmojis", {
                                model: {
                                  value: _vm.reaction,
                                  callback: function($$v) {
                                    _vm.reaction = $$v
                                  },
                                  expression: "reaction"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showEmail
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", id: "emailField" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "prepend-inner-icon": "email",
                              label: _vm.$t("textFields.email"),
                              placeholder: "janedoe@email.com",
                              outlined: "",
                              height: "68",
                              "data-cy": "email",
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.emailAddress,
                              callback: function($$v) {
                                _vm.emailAddress = $$v
                              },
                              expression: "emailAddress"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-start text-h6",
                      attrs: { cols: "12", id: "textField" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("feedback.page.moreInfoTitle")) +
                          " "
                      ),
                      _c("v-textarea", {
                        ref: "textArea",
                        staticClass: "text-area-height-limit",
                        attrs: {
                          outlined: "",
                          "auto-grow": "",
                          "no-resize": ""
                        },
                        model: {
                          value: _vm.textFeedback,
                          callback: function($$v) {
                            _vm.textFeedback = $$v
                          },
                          expression: "textFeedback"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                id: "submitButton",
                                rounded: "",
                                color: "primary",
                                "min-width": "200px",
                                disabled: _vm.sending,
                                loading: _vm.sending
                              },
                              on: { click: _vm.submitFeedback }
                            },
                            [_vm._v(_vm._s(_vm.$t("buttons.submit")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }